<template>
  <el-dialog
    :visible.sync="showTransitDialogIncome"
    width="90%"
    :before-close="closeDialog"
    append-to-body
    title="Pick a Date a Time and a City"
    class="common-dialog"
  >
    <el-row type="flex" justify="center" :gutter="20">
      <el-col :span="24" :xs="24" :xl="24" class="flex-center">
        <TransitDateForm @closeDialog="closeDialog" />
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import TransitDateForm from "@/components/forms/TransitDateForm";

export default {
  name: "TransitDateDialog",
  components: {
    TransitDateForm
  },
  props: {
    showTransitDialogIncome: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeDialog(v) {
      /* this.$store.commit("SET_REG_ERRORS", "");
      this.$store.commit("SET_LOG_ERRORS", "");
      this.$store.commit("RESET_LOG_REG_FORMS", ""); */
      this.$emit("closeTransitDateDialog", v);
    }
  }
};
</script>
