<template>
  <el-dialog
    :title="activeName"
    :visible.sync="infoDialogIncome"
    :before-close="closeDialog"
    append-to-body
    width="90%"
    top="3vh"
    class="common-dialog"
  >
    <el-row
      type="flex-row"
      justify="center"
      :gutter="20"
      append-to-body
      class="xs-flex-direction-column"
    >
      <div style="margin-bottom:15px;text-align:center">
        <img
          src="https://life-chart.art/wp-content/uploads/logo.png"
          width="70"
          style="margin-bottom:45px"
        /><br />
        <span v-html="messageIncome"></span>
      </div>
      <!-- <el-card class="box-card" style="margin-bottom:15px">
        <el-row>
          <el-col :span="12" class="text-align-left">
            <el-checkbox v-model="checkBuyReports1" label="15 reports">
            </el-checkbox>
          </el-col>
          <el-col :span="12" class="text-align-right color-orange">
            $15 <i class="el-icon-money"></i>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card" style="margin-bottom:15px">
        <el-row>
          <el-col :span="12" class="text-align-left">
            <el-checkbox v-model="checkBuyReports2" label="30 reports">
            </el-checkbox>
          </el-col>
          <el-col :span="12" class="text-align-right color-orange">
            $30 <i class="el-icon-money"></i>
          </el-col>
        </el-row>
      </el-card>
      <el-card class="box-card" style="margin-bottom:15px">
        <el-row>
          <el-col :span="12" class="text-align-left">
            <el-checkbox v-model="checkBuyReports3" label="45 reports">
            </el-checkbox>
          </el-col>
          <el-col :span="12" class="text-align-right color-orange">
            $45 <i class="el-icon-money"></i>
          </el-col>
        </el-row>
      </el-card> -->
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">Very fine</el-button>
      <!-- <el-button @click="closeDialog" type="primary">
        Buy
      </el-button> -->
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "InfoDialog",
  props: {
    infoDialogIncome: {
      type: Boolean,
      default: false
    },
    messageIncome: String
  },
  data() {
    return {
      activeName: "",
      checkBuyReports1: false,
      checkBuyReports2: false,
      checkBuyReports3: false
    };
  },
  methods: {
    closeDialog() {
      this.$emit("openCloseInfoDialog", false);
    }
  }
};
</script>
