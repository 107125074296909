<template>
  <div>
    <el-row type="flex" justify="center" :gutter="20">
      <el-col :xs="24" :xl="24" class="report">
        <el-card class="box-card el-card-with-bg">
          <SendApplayForChartForm
            :email.sync="email"
            :closeButton.sync="closeButtonAttr"
            @openCloseInfoDialog="openCloseInfoDialog"
          />
        </el-card>
      </el-col>
    </el-row>
    <InfoDialog
      :infoDialogIncome.sync="infoDialog"
      :messageIncome="messageIncome"
      @openCloseInfoDialog="openCloseInfoDialog"
    />
  </div>
</template>

<script>
import Api from "@/api/api";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import { commonErrorsMethods } from "@/mixins/common-errors-methods";
import SendApplayForChartForm from "@/components/forms/SendApplayForChartForm";
import InfoDialog from "@/components/dialogs/InfoDialog";

export default {
  name: "Decoding",
  props: {
    email: {
      type: String,
      default: ""
    }
  },
  components: {
    SendApplayForChartForm,
    InfoDialog
  },
  data() {
    return {
      closeButtonAttr: false,
      infoDialog: false,
      messageIncome:
        "The mail has been sent successfully. All mails are processed in the order of arrival. On average, it takes from 2 to 4 hours."
    };
  },
  mixins: [commonInitAppMethods, commonReportingMethods, commonErrorsMethods],
  mounted() {
    this.initApp();
    this.getSettings();
  },
  methods: {
    async getSettings() {
      try {
        const result = await Api.getSettings();
        if (result.success) {
          // console.log(result.messages);
          this.$store.commit("SET_MESSAGES", result.messages);
          // console.log(this.$store.state.Messages);
        }
      } catch (e) {
        console.log(e);
      }
    },
    openCloseInfoDialog(v) {
      this.infoDialog = v;
    }
  }
};
</script>
<style scoped>
.report {
  min-width: 500px;
  max-width: 920px;
}
.report {
  min-width: initial;
}
</style>
