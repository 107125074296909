import { render, staticRenderFns } from "./Remind.vue?vue&type=template&id=07fa0acd&scoped=true&"
import script from "./Remind.vue?vue&type=script&lang=js&"
export * from "./Remind.vue?vue&type=script&lang=js&"
import style0 from "./Remind.vue?vue&type=style&index=0&id=07fa0acd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07fa0acd",
  null
  
)

export default component.exports