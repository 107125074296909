<template>
  <el-dialog
    :title="activeName"
    :visible.sync="toInflictDialogIncome"
    :before-close="closeDialog"
    append-to-body
    width="90%"
    top="3vh"
    class="common-dialog"
  >
    <el-row
      type="flex-row"
      justify="center"
      :gutter="20"
      append-to-body
      class="xs-flex-direction-column"
    >
      <div class="text-center" style="margin-bottom:15px">{{ infoDesc1 }}</div>
      <div class="flex-center">
        <ReportForm @newReportCreated="newReportCreated" />
      </div>
      <div v-if="bodyGraph && !bodyGraph.is_stored">
        <div class="text-center" style="margin:25px 0 15px 0">
          {{ infoDesc2 }}
        </div>
        <ActivReport
          :reportIncome.sync="bodyGraph"
          @selectedReport="selectedReport"
        />
      </div>
      <div v-if="isLogged">
        <div class="text-center" style="margin-bottom:15px">
          {{ infoDesc3 }}
        </div>
        <SaveReports
          :reportsIncome.sync="savedReports"
          @selectedReport="selectedReport"
        />
      </div>
    </el-row>
  </el-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Api from "@/api/api";
import { commonInitAppMethods } from "@/mixins/common-init-app-methods";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import ReportForm from "@/components/forms/ReportForm";
import ActivReport from "@/components/lists/ActivReport";
import SaveReports from "@/components/lists/SaveReports";

export default {
  name: "InflictionDialog",
  props: {
    toInflictDialogIncome: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ReportForm,
    ActivReport,
    SaveReports
  },
  data() {
    return {
      activeName: "Overlapping transit",
      infoDesc1:
        "You can find out how the asha chart will change under the influence of the transit of planets:",
      infoDesc2: "or select an active one:",
      infoDesc3: "or select a saved one:"
    };
  },
  mixins: [commonInitAppMethods, commonReportingMethods],
  computed: {
    ...mapState(["User"]),
    isLogged: {
      get() {
        return this.$store.getters.IS_LOGGED;
      },
      set(value) {
        this.$store.commit("IS_LOGIN", value);
      }
    },
    bodyGraph() {
      return this.User.chartBodygraph;
    },
    tranSit() {
      return this.User.chartTransit;
    },
    savedReports() {
      return this.User.profile.reports;
    }
  },
  mounted() {
    this.checkIsbodyGraph();
  },
  methods: {
    ...mapMutations(["SET_INFLICTION"]),
    checkIsbodyGraph() {
      this.getItemFromLocalStorage("chartBodygraph", "SET_BODYGRAPH");
    },
    newReportCreated(id) {
      this.createInfliction(id);
    },
    selectedReport(id) {
      this.createInfliction(id);
    },
    closeDialog() {
      this.$emit("closeInflictionDialog", true);
    },
    async createInfliction(id) {
      const data = {
        report_id: id,
        transit_birthday: this.tranSit.birthday,
        transit_city_id: this.tranSit.city.id
      };
      try {
        const result = await Api.createInfliction(data);
        if (result.success) {
          // console.log(result);
          this.SET_INFLICTION(result.report);
          this.matchInflictionChannels();
          this.closeDialog();
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
