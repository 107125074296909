<template>
  <div>
    <el-tabs v-model="activeName" stretch>
      <el-tab-pane label="Login" name="Login">
        <el-row
          type="flex"
          justify="center"
          :gutter="20"
          class="xs-flex-direction-column"
        >
          <div class="log-errors">{{ logErrors }}</div>
        </el-row>
        <el-row
          type="flex"
          justify="center"
          :gutter="20"
          class="xs-flex-direction-column"
        >
          <el-form
            :model="modelLogin"
            :rules="rulesLogin"
            ref="loginForm"
            class="login-form"
          >
            <el-form-item class="margin-bt-30">
              <el-col :xs="24" :sm="12" class="mob-margin-bt-20">
                <el-form-item prop="email" required>
                  <div class="grid-content">
                    <el-input
                      placeholder="Email"
                      v-model="modelLogin.email"
                      autocomplete="off"
                      @blur="saveEmail(modelLogin.email)"
                    ></el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12">
                <el-form-item prop="psswd" required>
                  <div class="grid-content">
                    <el-input
                      placeholder="Please input password"
                      v-model="modelLogin.psswd"
                      autocomplete="off"
                      show-password
                    ></el-input>
                    <span v-if="closeButton" class="link remind-fixed-link">
                      <a @click="remindPassword()">Remind password</a>
                    </span>
                  </div>
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-form>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="Registration" name="Registration">
        <el-row
          type="flex"
          justify="center"
          :gutter="20"
          class="xs-flex-direction-column"
        >
          <div class="log-errors">{{ regErrors }}</div>
        </el-row>
        <el-row
          type="flex"
          justify="center"
          :gutter="20"
          class="xs-flex-direction-column"
        >
          <el-form
            :model="modelReg"
            :rules="rulesReg"
            ref="registerForm"
            class="login-form"
          >
            <el-form-item class="margin-bt-30">
              <el-col :xs="24" :sm="12" class="mob-margin-bt-20">
                <el-form-item prop="email" required>
                  <div class="grid-content">
                    <el-input
                      placeholder="Email"
                      v-model="modelReg.email"
                      autocomplete="off"
                      @blur="saveEmail(modelReg.email)"
                    ></el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12">
                <el-form-item prop="firstName" required>
                  <div class="grid-content">
                    <el-input
                      placeholder="Your Name"
                      v-model="modelReg.firstName"
                      autocomplete="off"
                    ></el-input>
                  </div>
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-form>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <span
      v-if="activeName === 'Login'"
      slot="footer"
      class="dialog-footer padding-bottom-25"
    >
      <el-button
        v-if="closeButton"
        @click="closeDialog"
        class="min-width-95 margin-right-5"
      >
        Cancel
      </el-button>
      <el-button
        @click="submitForm('loginForm')"
        type="primary"
        class="min-width-95"
      >
        Enter
      </el-button>
    </span>
    <span
      v-if="activeName === 'Registration'"
      slot="footer"
      class="dialog-footer padding-bottom-25"
    >
      <el-button
        v-if="closeButton"
        @click="closeDialog"
        class="min-width-95 margin-right-5"
      >
        Cancel
      </el-button>
      <el-button
        @click="submitForm('registerForm')"
        type="primary"
        class="min-width-95"
      >
        Register
      </el-button>
    </span>
    <!-- <div
      class="dialog-footer flex-direction-col text-center padding-bottom-mob-15 padding-bottom-desk-25"
    >
      {{ info }}
      <div class="padding-top-mob-15 padding-top-desk-25">
        <span class="link" v-html="supportLink"></span>
      </div>
    </div> -->
    <span
      v-if="isOrderBlock"
      slot="footer"
      class="dialog-footer padding-bottom-25"
    >
      <div class="text-align-center">
        <span class="title-dialog-ad-info margin-bt-10">
          {{ sendApplyForChartOfInformation }}
        </span>
        <el-button
          @click="
            closeDialog();
            scrollToInfoBlock();
          "
          type="primary2"
          class="min-width-125 margin-right-5"
        >
          View content
        </el-button>
        <el-button
          @click="openOrderDialog"
          class="min-width-125 margin-left-5"
          type="green"
        >
          Make order
        </el-button>
      </div>
    </span>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";
import Api from "@/api/api";

export default {
  name: "Login",
  props: {
    closeButton: {
      type: Boolean,
      default: true
    },
    isOrderBlock: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ""
    },
    activeNameTab: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      activeName: this.activeNameTab,
      modelLogin: {
        email: this.email,
        psswd: ""
      },
      modelReg: {
        firstName: "",
        email: this.email
      },
      rulesLogin: {
        email: [
          {
            required: true,
            message: "Please enter your email address",
            trigger: "blur"
          },
          {
            type: "email",
            message: "Please enter a valid email address",
            trigger: "blur"
          }
        ],
        psswd: [
          {
            required: true,
            message: "Please input Your password",
            trigger: "blur"
          },
          {
            min: 6,
            max: 18,
            message: "Length should be 6 to 18",
            trigger: "blur"
          }
        ]
      },
      rulesReg: {
        email: [
          {
            required: true,
            message: "Please enter your email address",
            trigger: "blur"
          },
          {
            type: "email",
            message: "Please enter a valid email address",
            trigger: "blur"
          }
        ],
        firstName: [
          {
            required: true,
            message: "Please input Your name",
            trigger: "blur"
          }
        ]
      },
      info: "You can install our calculation system on your website for free",
      supportLink:
        "<i class='el-icon-s-comment'></i> <a href='mailto:info@life-chart.art?subject=Support'>Support</a>",
      sendApplyForChartOfInformation:
        "You can order a complete decoding your chart in one document here",
      sendApplayForChartFormLink:
        "https://life-chart.art/#/?anchor=DesignTranscribed"
    };
  },
  mixins: [commonReportingMethods],
  computed: {
    ...mapState(["User"]),
    isLogged: {
      get() {
        return this.$store.getters.IS_LOGGED;
      },
      set(value) {
        this.$store.commit("IS_LOGIN", value);
      }
    },
    name: {
      get() {
        return this.$store.state.User.name;
      },
      set(value) {
        this.$store.commit("SET_NAME", value);
      }
    },
    token: {
      get() {
        return this.$store.state.User.access_token;
      },
      set(value) {
        this.$store.commit("SET_TOKEN", value);
      }
    },
    regErrors: {
      get() {
        return this.$store.state.Forms.regErrors;
      },
      set(value) {
        this.$store.commit("SET_REG_ERRORS", value);
      }
    },
    logErrors: {
      get() {
        return this.$store.state.Forms.logErrors;
      },
      set(value) {
        this.$store.commit("SET_LOG_ERRORS", value);
      }
    }
  },
  mounted() {
    this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "RESET_LOG_REG_FORMS":
          this.resetForm("registerForm");
          this.resetForm("loginForm");
          break;
      }
    });
  },
  methods: {
    ...mapMutations(["SET_BODYGRAPH", "IS_EMAIL"]),
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (formName === "loginForm") {
            const data = {
              email: this.modelLogin.email,
              password: this.modelLogin.psswd
            };
            this.getLogin(data);
          } else if (formName === "registerForm") {
            const data = {
              email: this.modelReg.email,
              first_name: this.modelReg.firstName
            };
            this.getRegister(data);
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      // toggle to income undefined
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields();
      }
    },
    saveEmail(v) {
      localStorage.setItem("email", v);
    },
    async getLogin(data) {
      try {
        const result = await Api.login(data);
        // console.log(result);
        if (result.success) {
          this.name = result.profile.first_name;
          this.token = result.access_token;
          this.isLogged = true;
          localStorage.setItem("name", result.profile.first_name);
          localStorage.setItem("email", result.profile.email);
          localStorage.setItem("activeNameTab", "Login");
          localStorage.setItem("token", result.access_token);
          this.$store.commit("SET_USER_ID", result.profile.id);
          this.$store.commit("SET_EMAIL", result.profile.email);
          this.resetForm("loginForm");
          this.$emit("close", true);
          this.isMyReportsCheck();
          if (result.profile.is_email_confirmed) {
            this.IS_EMAIL(true);
          } else {
            this.IS_EMAIL(false);
          }
        } else {
          this.logErrors = result.errors.password[0];
        }
      } catch (e) {
        console.log(e);
        this.logErrors = e.response.data.errors.password[0];
      }
    },
    async getRegister(data) {
      try {
        const result = await Api.register(data);
        if (result.success) {
          this.name = result.profile.first_name;
          this.token = result.access_token;
          this.isLogged = true;
          localStorage.setItem("name", result.profile.first_name);
          localStorage.setItem("email", result.profile.email);
          localStorage.setItem("activeNameTab", "Login");
          localStorage.setItem("token", result.access_token);
          this.$store.commit("SET_USER_ID", result.profile.id);
          this.$store.commit("SET_EMAIL", result.profile.email);
          this.resetForm("registerForm");
          this.$emit("close", "regSuccess");
          this.isMyReportsCheck();
        } else {
          this.regErrors = result.errors.email[0];
        }
      } catch (e) {
        console.log(e);
        this.regErrors = e.response.data.errors.email[0];
      }
    },
    remindPassword() {
      this.$emit("openRemind", true);
      this.closeDialog();
    },
    isMyReportsCheck() {
      // Проверка репортов на то что они принадлежат юзеру
      const chartBodygraph = JSON.parse(localStorage.getItem("chartBodygraph"));
      const chartComposite = JSON.parse(localStorage.getItem("chartComposite"));
      if (
        !chartBodygraph ||
        !chartBodygraph.user_id ||
        this.User.id !== chartBodygraph.user_id
      ) {
        localStorage.removeItem("chartBodygraph");
        this.$store.commit("SET_BODYGRAPH", null);
      }
      if (
        !chartComposite ||
        !chartComposite.user_id ||
        this.User.id !== chartComposite.user_id
      ) {
        localStorage.removeItem("chartComposite");
        this.$store.commit("SET_COMPOSITE", null);
      }
    },
    closeDialog() {
      this.regErrors = "";
      this.logErrors = "";
      this.resetForm("loginForm");
      this.resetForm("registerForm");
      this.$emit("close", true);
    },
    openOrderDialog() {
      this.$emit("openOrderDialogEvent");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.log-errors {
  color: coral;
}
.login-form {
  min-width: 100% !important;
}
@media screen and (min-width: 320px) and (max-width: 420px) {
  .el-col {
    margin-bottom: 7px;
  }
  .el-col:last-child {
    margin-bottom: 0;
  }
  .login-form {
    min-width: initial !important;
  }
}
</style>
