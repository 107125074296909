<template>
  <div>
    <el-row
      type="flex"
      justify="center"
      :gutter="20"
      class="xs-flex-direction-column"
    >
      <div v-html="getMessage('apply_for_chart_decode')"></div>
      <div class="log-errors">{{ applayForChartDecodeErrors }}</div>
    </el-row>
    <el-row
      type="flex"
      justify="center"
      :gutter="20"
      class="xs-flex-direction-column"
    >
      <el-form
        :model="modelApplayForChart"
        :rules="rulesApplayForChart"
        ref="applayForChartForm"
        class="Send-applay-for-chart-form"
      >
        <el-form-item class="margin-bt-0">
          <el-col :xs="24" :sm="24" class="margin-bt-30">
            <el-form-item prop="email" required>
              <div class="grid-content">
                <el-input
                  placeholder="Email"
                  v-model="modelApplayForChart.email"
                  autocomplete="off"
                  @blur="saveEmail(modelApplayForChart.email)"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24">
            <el-form-item prop="name" required>
              <div class="grid-content">
                <el-input
                  placeholder="Name"
                  v-model="modelApplayForChart.name"
                  autocomplete="off"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-form-item>
      </el-form>
    </el-row>
    <span slot="footer" class="dialog-footer padding-bottom-25">
      <el-button v-if="closeButton" @click="closeDialog" class="min-width-150">
        Cancel
      </el-button>
      <el-button
        @click="submitForm('applayForChartForm')"
        type="primary"
        class="min-width-150"
      >
        Send
      </el-button>
    </span>
  </div>
</template>

<script>
import Api from "@/api/api";
import { commonReportingMethods } from "@/mixins/common-reporting-methods";

export default {
  name: "applayForChartForm",
  props: {
    closeButton: {
      type: Boolean,
      default: true
    },
    email: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      modelApplayForChart: {
        email: this.email,
        name: this.name
      },
      rulesApplayForChart: {
        email: [
          {
            required: true,
            message: "Please enter your email address",
            trigger: "blur"
          },
          {
            type: "email",
            message: "Please enter a valid email address",
            trigger: "blur"
          }
        ],
        name: [
          {
            required: true,
            message: "Please input Your name",
            trigger: "blur"
          },
          {
            min: 3,
            max: 20,
            message: "Length should be 3 to 20",
            trigger: "blur"
          }
        ]
      }
    };
  },
  mixins: [commonReportingMethods],
  computed: {
    isLogged: {
      get() {
        return this.$store.getters.IS_LOGGED;
      },
      set(value) {
        this.$store.commit("IS_Remind", value);
      }
    },
    name: {
      get() {
        return this.$store.state.User.name;
      },
      set(value) {
        this.$store.commit("SET_NAME", value);
      }
    },
    token: {
      get() {
        return this.$store.state.User.access_token;
      },
      set(value) {
        this.$store.commit("SET_TOKEN", value);
      }
    },
    applayForChartDecodeErrors: {
      get() {
        return this.$store.state.Forms.applayForChartDecodeErrors;
      },
      set(value) {
        this.$store.commit("SET_APPLAY_FOR_CHART_ERRORS", value);
      }
    }
  },
  mounted() {
    this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "RESET_APPLAY_FOR_CHART_FORMS":
          this.resetForm("applayForChartForm");
          break;
      }
    });
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = {
            email: this.modelApplayForChart.email,
            name: this.modelApplayForChart.name
          };
          this.sendApplayForChart(data);
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      // toggle to income undefined
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields();
      }
    },
    saveEmail(v) {
      localStorage.setItem("email", v);
    },
    async sendApplayForChart(data) {
      try {
        const result = await Api.sendApplayForChart(data);
        // console.log(result);
        if (result.success) {
          this.resetForm("applayForChartForm");
          this.$emit("close", true);
          this.$emit("openCloseInfoDialog", true);
        } else {
          this.applayForChartDecodeErrors = result.errors.email[0];
        }
      } catch (e) {
        // console.log(e);
        this.applayForChartDecodeErrors = e.response.data.errors.email[0];
      }
    },
    closeDialog() {
      this.applayForChartDecodeErrors = "";
      this.resetForm("applayForChartForm");
      this.$emit("close", true);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.log-errors {
  color: coral;
}
.Send-applay-for-chart-form {
  min-width: 30% !important;
}
@media screen and (min-width: 320px) and (max-width: 420px) {
  .el-col {
    margin-bottom: 7px;
  }
  .el-col:last-child {
    margin-bottom: 0;
  }
  .Send-applay-for-chart-form {
    min-width: initial !important;
  }
}
</style>
