<template>
  <div>
    <el-table
      :data="reportsIncome"
      class="no-mobile"
      style="width:100%;margin-bottom:25px"
    >
      <el-table-column label="Name" min-width="90">
        <template slot-scope="scope">
          <span>
            {{ scope.row.first_name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Birthday" min-width="90">
        <template slot-scope="scope">
          <span>
            {{ scope.row.birthday }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Type" min-width="90">
        <template slot-scope="scope">
          <span>
            {{ scope.row.chart_type }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Profile" min-width="100">
        <template slot-scope="scope">
          <span>
            {{ scope.row.chart_profile_title }}
            {{ scope.row.chart_profile }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Operations" min-width="90">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-document-checked"
            @click="getReport(scope.row.id)"
            round
          >
            Select
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex no-desktop" v-for="(report, i) in reportsIncome" :key="i">
      <el-col :span="8" :xs="24">
        <el-card
          shadow="never"
          class="min-height-160"
          style="margin-bottom:25px"
        >
          <ul class="ul-no-li-style">
            <li v-if="report.first_name">
              <i class="el-icon-user"></i>
              <span>{{ report.first_name }}</span>
            </li>
            <li v-if="report.birthday">
              <span>{{ report.birthday }}</span>
            </li>
            <li v-if="report.chart_type">
              <span>{{ report.chart_type }}</span>
            </li>
            <li v-if="report.chart_profile">
              <span
                >{{ report.chart_profile_title }}
                {{ report.chart_profile }}
              </span>
            </li>
          </ul>
          <div>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-document-checked"
              @click="getReport(report.id)"
              round
            >
              Select
            </el-button>
          </div>
        </el-card>
      </el-col>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaveReports",
  props: {
    reportsIncome: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      // reportArr: []
    };
  },
  mounted() {
    console.log("reportsIncome: ", this.reportsIncome);
  },
  methods: {
    getReport(id) {
      this.$emit("selectedReport", id);
    }
  }
};
</script>
<style scoped>
.ul-no-li-style {
  text-align: left;
  padding-left: 0 !important;
}
.ul-no-li-style li {
  list-style-type: none;
}
.ul-no-li-style li i {
  padding-right: 5px;
}
</style>
