import Store from "@/store/index";

export const commonInitAppMethods = {
  methods: {
    initApp: () => {
      // Don't context menu & selection call
      document.oncontextmenu = () => {
        return false;
      };
      document.onselectstart = () => false;
      if (!localStorage.getItem("isSecondTime")) {
        Store.commit("IS_FIRST_TIME", true);
      } else {
        Store.commit("IS_FIRST_TIME", false);
      }
      if (localStorage.getItem("token")) {
        Store.commit("SET_NAME", localStorage.getItem("name"));
        Store.commit("SET_TOKEN", localStorage.getItem("token"));
        Store.commit("IS_LOGIN", true);
      }
      Store.commit("SET_EMAIL", localStorage.getItem("email"));
      if (localStorage.getItem("activeNameTab")) {
        Store.commit(
          "SET_ACTIVE_NAME_TAB",
          localStorage.getItem("activeNameTab")
        );
      }
    },
    test: () => {
      console.log("Test done!");
    },
    getItemFromLocalStorage: (item, method) => {
      if (
        localStorage.getItem(item) &&
        localStorage.getItem(item) !== "undefined"
      ) {
        Store.commit(method, JSON.parse(localStorage.getItem(item)));
      }
    },
    setItemToLocalStorage: (name, item) => {
      localStorage.setItem(name, item);
    }
  }
};
