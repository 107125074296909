<template>
  <div class="about">
    <el-dialog
      :title="afterReg"
      :visible.sync="afterSuccessRegDialog"
      width="90%"
      :before-close="closeDialog"
      append-to-body
      class="common-dialog"
    >
      <el-row
        type="flex"
        justify="center"
        :gutter="20"
        class="xs-flex-direction-column"
      >
        <img
          src="https://life-chart.art/wp-content/uploads/confirm.png"
          width="130"
          style="margin-bottom:10px"
        /><br />
        <div
          class="text-center"
          v-html="email"
          style="margin-bottom:30px"
        ></div>
        <div class="text-center" v-html="infoAboutSuccessRegistration"></div>
      </el-row>
      <span slot="footer" class="dialog-footer" style="margin-right:5px">
        <el-button @click="closeDialog" class="min-width-130">Close</el-button>
        <el-button @click="getExit" type="primary" class="min-width-130">
          Change email
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AfterSuccessRegDialog",
  props: {
    afterSuccessRegDialog: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      afterReg: "Check your email",
      infoAboutSuccessRegistration:
        "We have sent you access and a link to complete your registration.<br />The email may go to spam."
    };
  },
  computed: {
    ...mapState(["User"]),
    isLogged: {
      get() {
        return this.$store.getters.IS_LOGGED;
      },
      set(value) {
        this.$store.commit("IS_LOGIN", value);
      }
    },
    name: {
      get() {
        return this.$store.state.User.name;
      },
      set(value) {
        this.$store.commit("SET_NAME", value);
      }
    },
    email: {
      get() {
        return this.$store.state.User.email;
      },
      set(value) {
        this.$store.commit("SET_EMAIL", value);
      }
    },
    token: {
      get() {
        return this.$store.state.User.access_token;
      },
      set(value) {
        this.$store.commit("SET_TOKEN", value);
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit("closeSucRegDialog", false);
    },
    async getExit() {
      this.name = "";
      this.token = "life-chart-token";
      this.isLogged = false;
      localStorage.removeItem("name");
      localStorage.removeItem("token");
      this.$emit("closeSucRegDialog", false);
    }
  }
};
</script>
