<template>
  <div id="app">
    <el-container>
      <el-header class="m-header">
        <div id="nav" class="menu">
          <router-link to="/" class="common">Chart</router-link>
          <router-link to="/composite" class="composite">Composite</router-link>
          <!-- <router-link v-if="isLogged" to="/decoding" class="decoding">
            Decoding
          </router-link> -->
          <router-link to="/transit" class="transition">
            Transit
          </router-link>
          <router-link to="/profile" class="profile">My Profile</router-link>
        </div>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
    <ContextContentBlock />
  </div>
</template>
<script>
import ContextContentBlock from "@/components/blocks/ContextContentBlock";

export default {
  components: {
    ContextContentBlock
  }
};
</script>

<style scoped>
.menu a {
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: 500 !important;
  padding: 12px 20px !important;
  font-size: 14px !important;
  color: #606266 !important;
  border-radius: 20px;
  border: 1px solid #dcdfe6;
  margin: 0 3px;
}
.menu a.common:hover {
  color: #4c4e86 !important;
  border: 1px solid #bcbeff;
  background-color: #f0f0fd;
  text-decoration: none !important;
}
.menu a.composite:hover {
  color: #e6a23c !important;
  background-color: #fdf6ec;
  border: 1px solid #f5dab1;
  text-decoration: none !important;
}
.menu a.decoding:hover {
  color: #e6613c !important;
  background-color: #fdf6ec;
  border: 1px solid #f5b6a9;
  text-decoration: none !important;
}
.menu a.transition:hover {
  color: #5b27a2 !important;
  border: 1px solid #9864de;
  background-color: #f2e8ff;
  text-decoration: none !important;
}
.menu a.profile:hover {
  color: #67c23a !important;
  background-color: #f0f9eb;
  border: 1px solid #c2e7b0;
  text-decoration: none !important;
}
.menu a.common:active {
  border: 1px solid #6062a4;
}
.menu a.composite:active {
  border: 1px solid #dfaa5b;
}
.menu a.decoding:active {
  border: 1px solid #e6613c;
}
.menu a.transition:active {
  border: 1px solid #9864de;
}
.menu a.profile:active {
  border: 1px solid #67c23a;
}

.menu .common.router-link-exact-active {
  color: #4c4e86 !important;
  background-color: #f0f0fd;
  border: 1px solid #bcbeff;
}
.menu .composite.router-link-exact-active {
  color: #e6a23c !important;
  background-color: #fdf6ec;
  border: 1px solid #f5dab1;
}
.menu .decoding.router-link-exact-active {
  color: #e6613c !important;
  background-color: #fdf6ec;
  border: 1px solid #f5b6a9;
}
.menu .transition.router-link-exact-active {
  color: #5b27a2 !important;
  border: 1px solid #9864de;
  background-color: #f2e8ff;
}
.menu .profile.router-link-exact-active {
  color: #67c23a !important;
  background-color: #f0f9eb;
  border: 1px solid #c2e7b0;
  text-decoration: none !important;
}
</style>
