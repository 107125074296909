import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/composite",
    name: "Composite",
    component: () => import("../views/Composite.vue")
  },
  {
    path: "/transit",
    name: "Transit",
    component: () => import("../views/Transit.vue")
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue")
  },
  {
    path: "/decoding",
    name: "Decoding",
    component: () => import("../views/Decoding.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;
