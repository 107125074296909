<template>
  <el-dialog
    :visible.sync="showRemindDialogIncome"
    width="90%"
    :before-close="closeDialog"
    append-to-body
    title="To remind your password, enter your email"
    class="common-dialog"
  >
    <Remind :email.sync="email" @close="closeDialog" />
  </el-dialog>
</template>

<script>
import Remind from "@/components/forms/Remind";

export default {
  name: "RemindDialog",
  components: {
    Remind
  },
  props: {
    showRemindDialogIncome: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ""
    }
  },
  methods: {
    closeDialog(v) {
      this.$store.commit("SET_REM_ERRORS", "");
      this.$store.commit("RESET_LOG_REG_FORMS", "");
      this.$emit("close", v);
    }
  }
};
</script>
