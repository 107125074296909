<template>
  <div>
    <OrderDialog
      :orderDialogIncome.sync="orderDialog"
      :emailIncome="emailIncome"
      :nameIncome="nameOfReport"
      @closeOrderDialog="closeOrderDialog"
    />
    <!-- Outer content block -->
    <section
      id="DesignTranscribed"
      class="chart-section-content-middle chart-section-padding"
    >
      <div class="chart-widget-wrap chart-element-populated">
        <div v-if="$route.path === '/composite'" class="chart-widget-container">
          <h2 class="chart-heading-title">
            Receive a comprehensive PDF analysis of your compatibility in just 5
            minutes.
          </h2>
          <p style="text-align: center;">
            <span style="font-size: 14pt;">
              Gain a new perspective on your relationship.
            </span>
          </p>
          <p style="text-align: center;">
            <span style="font-size: 14pt;">
              In the past year, we have analyzed the interactions of all chart
              elements to create this guide, complete with clear examples and
              actionable advice.
            </span>
          </p>
          <p style="text-align: center;">
            <span style="font-size: 14pt;">
              You will gain a clear understanding of what you and your partner
              can offer each other in your relationship and what to expect
              moving forward.
            </span>
          </p>
          <p style="text-align: center;">
            <span style="font-size: 14pt;">
              These are the topics you will be exploring:
            </span>
          </p>
          <p
            class="background-light-green"
            style="text-align: left; font-size: 14pt;"
          >
            <strong>How to Use the Composite Chart.</strong> We’ll guide you on
            how to navigate your Composite Chart. It offers deep insights into
            the dynamics of your relationship, revealing which aspects
            strengthen your bond and which create tension. This will help you
            interact more effectively, understanding where to focus in your
            partnership.
          </p>
          <p
            class="background-light-purple"
            style="text-align: left; font-size: 14pt;"
          >
            <strong>The Love Formula</strong> Love is not just emotional
            connection but also finding inspiration through shared or individual
            experiences. Discover how to keep the spark alive and maintain
            balance without losing yourself in the process.
          </p>
          <p
            class="background-light-blue"
            style="text-align: left; font-size: 14pt;"
          >
            <strong>Type Compatibility</strong> Each type interacts differently.
            Our guide provides instructions on how to communicate more
            effectively and handle conflicts based on the unique traits of you
            and your partner.
          </p>
          <p
            class="background-purple"
            style="text-align: left; font-size: 14pt;"
          >
            <strong>Profile Analysis.</strong> Every profile reveals the roles
            you and your partner play in the relationship. Understanding these
            roles can clarify who takes the lead and who offers support,
            fostering better mutual understanding.
          </p>
          <p style="text-align: center;">
            <span style="font-size: 14pt;">
              Decoding the 4 Types of Centers
            </span>
          </p>
          <p>
            Centers show how you and your partner use energy in the
            relationship:
          </p>
          <ol>
            <li>
              Companion Centers: Even with similar centers, the way they are
              expressed can differ.
            </li>
            <li>
              Dominant Centers: One partner may dominate or, alternatively,
              empower the other.
            </li>
            <li>
              Undefined Centers: Look outside the relationship for balance and
              energy.
            </li>
            <li>
              Electromagnetic Centers: Create harmony by understanding how your
              energies combine.
            </li>
          </ol>
          <p style="text-align: center;">
            <span style="font-size: 14pt;">
              Exploring the Compatibility of 4 Channel Types
            </span>
          </p>
          <p>
            Channels in a relationship can enhance or challenge your connection.
            Learn how your relationship is influenced by:
          </p>
          <ul>
            <li>
              <span style="color: rgb(146, 205, 220);">Companion Channels</span>
              : Moving in a similar direction together.
            </li>
            <li>
              <span style="color: rgb(195, 214, 155);">
                Electromagnetic Channels
              </span>
              : Attraction through opposites.
            </li>
            <li>
              <span style="color: rgb(149, 179, 215);">
                Compromise Channels
              </span>
              : Balancing through compromise.
            </li>
            <li>
              <span style="color: rgb(250, 192, 143);">Learning Channels</span>
              : Growing and evolving as a couple.
            </li>
          </ul>
        </div>
        <div v-else class="chart-widget-container">
          <h2 class="chart-heading-title">
            Get your chart decoded by the professionals
          </h2>
          <p style="text-align: center;">
            <span style="font-size: 14pt;">
              Quality descriptions will help you discover and realize your
              potential.
            </span>
          </p>
          <p style="text-align: center;">
            <span style="font-size: 14pt;">
              You will receive a personalized, structured document that decodes
              these themes:
            </span>
          </p>
          <p
            class="background-light-green"
            style="text-align: left; font-size: 14pt;"
          >
            <strong>Type.</strong> You will learn as much as possible about your
            type: its purpose, advantages and disadvantages, correct
            development, relationships, sleep, and other important areas.
          </p>
          <p
            class="background-light-purple"
            style="text-align: left; font-size: 14pt;"
          >
            <strong>Inner Authority.</strong> You will find out more about the
            source of wisdom within yourself and also learn how to use it to
            make the right decisions without regretting them.
          </p>
          <p
            class="background-light-blue"
            style="text-align: left; font-size: 14pt;"
          >
            <strong>Strategy of your type.</strong> This part tells you how to
            properly use the energy of your type and what it shouldn’t be used
            for. The information is provided with examples and practical
            recommendations.
          </p>
          <p
            class="background-purple"
            style="text-align: left; font-size: 14pt;"
          >
            <strong>False Self.</strong> You will learn about the destructive
            states that may appear when you ignore the strategy of your type. We
            will teach you how to deal with them.
          </p>
          <p
            class="background-light-green"
            style="text-align: left; font-size: 14pt;"
          >
            <strong>Life signature.</strong> This part is dedicated to what
            rewards life brings when you implement your design correctly. You
            will learn the details of how to achieve this.
          </p>
          <p
            class="background-light-blue"
            style="text-align: left; font-size: 14pt;"
          >
            <strong>Profile.</strong> Patterns/roles of your behavior. You will
            discover the areas and conditions that will make it easier for you
            to express yourself in public.
          </p>
          <p
            class="background-light-purple"
            style="text-align: left; font-size: 14pt;"
          >
            <strong>Definition.</strong> Self-sufficiency. We will tell you
            about your dependence on other people and how it is expressed. You
            will receive recommendations on how to use it to your advantage.
          </p>
          <p
            class="background-light-green"
            style="text-align: left; font-size: 14pt;"
          >
            <strong>Centers defined.</strong> These are the areas of life in
            which you influence other people, becoming some kind of mentors for
            them.
          </p>
          <p
            class="background-light-blue"
            style="text-align: left; font-size: 14pt;"
          >
            <strong>Centers undefined.</strong> This includes your
            instability/changeability and vulnerabilities. This is a real school
            of life. In these areas you are constantly affected by others. You
            will learn what to do about it.
          </p>
          <p
            class="background-light-purple"
            style="text-align: left; font-size: 14pt;"
          >
            <strong>Channels.</strong> Your basic skills and social adaptation
            methods. You should find out more about them and learn how to use
            them correctly.
          </p>
          <p
            class="background-light-green"
            style="text-align: left; font-size: 14pt;"
          >
            <strong>Gates dormant </strong>(located in an undefined center).
            These are your personal characteristics that cannot be emphasized in
            development. Otherwise, it will be just a waste of effort and time.
          </p>
          <p
            class="background-light-blue"
            style="text-align: left; font-size: 14pt;"
          >
            <strong>Gates hanging</strong> (located in a defined center). These
            are your characteristics that help implement your channels and do it
            in a unique way.
          </p>
        </div>
        <div class="chart-widget-container">
          <p>
            <span style="font-size: 14pt;">
              <em>A few photos from the document:</em>
            </span>
          </p>
          <el-carousel indicator-position="outside" class="swiper">
            <el-carousel-item>
              <img
                decoding="async"
                src="https://life-chart.art/wp-content/uploads/2024/04/03.png"
              />
            </el-carousel-item>
            <el-carousel-item>
              <img
                decoding="async"
                src="https://life-chart.art/wp-content/uploads/2024/04/04.png"
              />
            </el-carousel-item>
          </el-carousel>
          <p>
            <span style="font-size: 14pt;">
              <b>The volume of information is 100 pages.</b>
            </span>
          </p>
          <p>
            <span style="font-size: 14pt;">
              All texts are written in an accessible way. We tell everything
              with examples and give many examples and recommendations.
            </span>
          </p>
          <p>
            <span style="font-size: 14pt;">
              This is a real manual for life.
            </span>
          </p>
          <img
            decoding="async"
            src="https://life-chart.art/wp-content/themes/life-chart/images/gid-2-200x.png"
          />
          <p>
            <span style="font-size: 14pt;"><b>Ideal for beginners.</b></span>
          </p>
          <p>
            <span style="font-size: 14pt;">
              You won’t find texts of similar quality on the internet.
            </span>
          </p>
          <p>
            <span style="font-size: 24pt; color: #47599e;">Price today</span>
          </p>
          <p>
            <span style="font-size: 18pt;">
              Instead of 35, it’s only 19 $<br />
            </span>
          </p>
          <p>
            <span style="font-size: 14pt;">
              The document is done 1-2 hours after payment. It can be translated
              into any language in the world.
            </span>
          </p>
          <p>
            <span style="color: #6f66b0; font-size: 14pt;">
              We will refund your money if you are not satisfied with our
              products.
            </span>
          </p>
          <div class="chart-widget-wrap">
            <el-button @click="openOrderDialog" class="chart-form-button">
              Get decoding
            </el-button>
          </div>
        </div>
      </div>
    </section>
    <!-- /Outer content block -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import OrderDialog from "@/components/dialogs/OrderDialog";

export default {
  name: "ContextContentBlock",
  props: {
    typeContextIncome: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      orderDialog: false
    };
  },
  components: {
    OrderDialog
  },
  mounted() {
    //
  },
  computed: {
    ...mapState(["User"]),
    isLogged: {
      get() {
        return this.$store.getters.IS_LOGGED;
      },
      set(value) {
        this.$store.commit("IS_LOGIN", value);
      }
    },
    nameOfReport() {
      const chartBodygraph = JSON.parse(localStorage.getItem("chartBodygraph"));
      return this.User.reportName
        ? this.User.reportName
        : chartBodygraph
        ? chartBodygraph.first_name
        : "";
    },
    emailIncome() {
      return this.User.email ? this.User.email : localStorage.getItem("email");
    }
  },
  methods: {
    openOrderDialog() {
      this.orderDialog = true;
    },
    closeOrderDialog() {
      this.orderDialog = false;
    }
  }
};
</script>
<style scoped>
#DesignTranscribed {
  color: #5c5c5c;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.1rem;
  line-height: 1.5;
  margin: 0 2px;
}
.chart-section-content-middle {
  text-align: center;
}
.chart-section-padding {
  padding: 5px 0 60px 0;
}
.chart-element-populated {
  padding: 0 15% 0 15%;
}
.chart-widget-wrap {
  align-content: center;
  align-items: center;
}
h2.chart-heading-title {
  font-size: 26px;
  font-weight: bold;
  text-transform: uppercase;
  color: #485184;
}
h2 {
  margin: 45px auto 45px auto;
}
p {
  margin-bottom: 1.5em;
  font-size: 14pt;
  color: #404040;
}
.background-light-green,
.background-light-purple,
.background-light-blue,
.background-purple {
  padding: 6px 8px;
  border-radius: 10px;
  margin-bottom: 0 !important;
}
.background-light-green {
  background-color: #f5fbf3;
}
.background-light-purple {
  background-color: #fbf4fd;
}
.background-light-blue {
  background-color: #f4fafd;
}
.background-purple {
  background: rgba(244, 244, 253, 0.8);
}
.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  display: block;
}
.swiper {
  max-width: 900px;
}
.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
  display: block;
}
img {
  height: auto;
  max-width: 100%;
  max-height: 550px;
}
img {
  border-style: none;
}
.chart-form-button {
  color: #fff;
  background-color: #4c4e86;
  border-color: #4c4e86;
  border-radius: 6px;
  border-style: none;
  height: 45px;
  text-transform: uppercase;
  transition: transform 0.2s;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.1rem;
  line-height: 0.9rem !important;
  width: 215px;
}
.chart-form-button:hover {
  transform: scale(1.1);
  color: #fff;
  background-color: #4c4e86;
}
ul li,
ol li {
  text-align: left;
}
@media screen and (min-width: 320px) and (max-width: 450px) {
  .chart-section-padding {
    padding-bottom: 0;
  }
  .chart-element-populated {
    padding: 0;
  }
  .chart-widget-container {
    margin-bottom: 50px;
  }
}
</style>
