<template>
  <div>
    <el-row
      type="flex"
      justify="center"
      :gutter="20"
      class="xs-flex-direction-column"
    >
      <div class="log-errors">{{ remErrors }}</div>
    </el-row>
    <el-row
      type="flex"
      justify="center"
      :gutter="20"
      class="xs-flex-direction-column"
    >
      <el-form
        :model="modelRemind"
        :rules="rulesRemind"
        ref="remindForm"
        class="Remind-form"
      >
        <el-form-item class="margin-bt-30">
          <el-col :xs="24" :sm="24" class="mob-margin-bt-20">
            <el-form-item prop="email" required>
              <div class="grid-content">
                <el-input
                  placeholder="Email"
                  v-model="modelRemind.email"
                  autocomplete="off"
                  @blur="saveEmail(modelRemind.email)"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-form-item>
      </el-form>
    </el-row>
    <span slot="footer" class="dialog-footer padding-bottom-25">
      <el-button v-if="closeButton" @click="closeDialog" class="min-width-95">
        Cancel
      </el-button>
      <el-button
        @click="submitForm('remindForm')"
        type="primary"
        class="min-width-95"
      >
        Remind
      </el-button>
    </span>
  </div>
</template>

<script>
import Api from "@/api/api";

export default {
  name: "Remind",
  props: {
    closeButton: {
      type: Boolean,
      default: true
    },
    email: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      activeName: this.activeNameTab,
      modelRemind: {
        email: this.email,
        psswd: ""
      },
      modelReg: {
        email: this.email
      },
      rulesRemind: {
        email: [
          {
            required: true,
            message: "Please enter your email address",
            trigger: "blur"
          },
          {
            type: "email",
            message: "Please enter a valid email address",
            trigger: "blur"
          }
        ]
      }
    };
  },
  computed: {
    isLogged: {
      get() {
        return this.$store.getters.IS_LOGGED;
      },
      set(value) {
        this.$store.commit("IS_Remind", value);
      }
    },
    name: {
      get() {
        return this.$store.state.User.name;
      },
      set(value) {
        this.$store.commit("SET_NAME", value);
      }
    },
    token: {
      get() {
        return this.$store.state.User.access_token;
      },
      set(value) {
        this.$store.commit("SET_TOKEN", value);
      }
    },
    remErrors: {
      get() {
        return this.$store.state.Forms.remErrors;
      },
      set(value) {
        this.$store.commit("SET_REM_ERRORS", value);
      }
    }
  },
  mounted() {
    this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "RESET_LOG_REM_FORMS":
          this.resetForm("remindForm");
          break;
      }
    });
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const data = {
            email: this.modelRemind.email
          };
          this.getRemind(data);
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      // toggle to income undefined
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields();
      }
    },
    saveEmail(v) {
      localStorage.setItem("email", v);
    },
    async getRemind(data) {
      try {
        const result = await Api.sendCredentials(data);
        // console.log(result);
        if (result.success) {
          this.resetForm("remindForm");
          this.$emit("close", true);
          this.$message({
            message: "Your password sent",
            type: "success"
          });
        } else {
          this.remErrors = result.errors.email[0];
        }
      } catch (e) {
        // console.log(e);
        this.remErrors = e.response.data.errors.email[0];
      }
    },
    async getRegister(data) {
      try {
        const result = await Api.register(data);
        if (result.success) {
          this.name = result.profile.first_name;
          this.token = result.access_token;
          this.isLogged = true;
          localStorage.setItem("name", result.profile.first_name);
          localStorage.setItem("email", result.profile.email);
          localStorage.setItem("activeNameTab", "Remind");
          localStorage.setItem("token", result.access_token);
          this.resetForm("registerForm");
          this.$emit("close", "regSuccess");
        } else {
          this.remErrors = result.errors.email[0];
        }
      } catch (e) {
        console.log(e);
        this.remErrors = e.response.data.errors.email[0];
      }
    },
    closeDialog() {
      this.remErrors = "";
      this.resetForm("remindForm");
      this.$emit("close", true);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.log-errors {
  color: coral;
}
.Remind-form {
  min-width: 100% !important;
}
@media screen and (min-width: 320px) and (max-width: 420px) {
  .el-col {
    margin-bottom: 7px;
  }
  .el-col:last-child {
    margin-bottom: 0;
  }
  .Remind-form {
    min-width: initial !important;
  }
}
</style>
