<template>
  <el-dialog
    :title="activeName"
    :visible.sync="orderDialogIncome"
    :before-close="closeDialog"
    append-to-body
    width="90%"
    top="3vh"
    class="common-dialog"
  >
    <el-row
      type="flex-row"
      justify="center"
      :gutter="20"
      append-to-body
      class="xs-flex-direction-column"
    >
      <div
        v-show="activeStep === 1"
        style="margin-bottom:15px;text-align:center"
      >
        <img
          v-if="$route.path === '/composite'"
          src="https://life-chart.art/wp-content/themes/life-chart/images/_04_3-pdf-composite.png"
          width="200"
        />
        <img
          v-else
          src="https://life-chart.art/wp-content/themes/life-chart/images/_02-pdf-min.png"
          width="200"
        />
        <br />
        <span class="el-dialog__title2">Get your chart decoded</span>
        <el-row
          type="flex"
          justify="center"
          :gutter="20"
          class="xs-flex-direction-column"
        >
          <el-form
            :model="modelStep1"
            :rules="rulesStep1"
            ref="orderStep1Form"
            class="login-form margin-top-15"
          >
            <el-form-item class="margin-bt-30">
              <el-col :xs="24" :sm="12" class="mob-margin-bt-20">
                <el-form-item prop="email" required>
                  <div class="grid-content">
                    <el-input
                      placeholder="Your Email"
                      v-model="modelStep1.email"
                      autocomplete="off"
                    ></el-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12">
                <el-form-item prop="name" required>
                  <div class="grid-content">
                    <el-input
                      placeholder="Your Name"
                      v-model="modelStep1.name"
                      autocomplete="off"
                    ></el-input>
                  </div>
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-form>
        </el-row>
      </div>
      <div
        v-show="activeStep === 2"
        style="margin-bottom:15px;text-align:center"
      >
        <img
          src="https://life-chart.art/wp-content/themes/life-chart/images/_03-bd-min.png"
          width="200"
        /><br />
        <span class="el-dialog__title2">Send us your birth information!</span>
        <br />
        <el-row
          type="flex"
          justify="center"
          :gutter="20"
          class="xs-flex-direction-column"
        >
          <el-form
            :model="modelStep2"
            :rules="rulesStep2"
            ref="orderStep2Form"
            class="report-form margin-top-15"
          >
            <el-form-item required>
              <el-col :span="5" class="padding-lt-0">
                <el-form-item prop="month">
                  <el-select v-model="modelStep2.month" placeholder="Month">
                    <el-option
                      v-for="item in months"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5" class="padding-lt-0">
                <el-form-item prop="day">
                  <el-select v-model="modelStep2.day" placeholder="Day">
                    <el-option
                      v-for="item in days"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7" class="padding-lt-0">
                <el-form-item prop="year">
                  <el-select v-model="modelStep2.year" placeholder="Year">
                    <el-option
                      v-for="item in years"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="7" class="padding-lt-0 padding-rt-0">
                <el-form-item prop="time">
                  <el-time-picker
                    placeholder="Pick a time of birthday"
                    v-model="modelStep2.time"
                    format="HH:mm"
                    style="width: 100%;"
                  ></el-time-picker>
                </el-form-item>
              </el-col>
            </el-form-item>
            <el-form-item prop="city" class="padding-0">
              <el-autocomplete
                class="inline-input width-100"
                v-model="modelStep2.city"
                :fetch-suggestions="querySearch"
                placeholder="Сity of birth"
                :trigger-on-focus="false"
                clearable
                value-key="title"
                @select="handleSelect"
                @change="handleChange"
              >
                <template slot-scope="{ item }">
                  <div class="city">
                    <div class="city-right-side">
                      <span class="city-title">
                        {{ item.title }}
                      </span>
                      <span class="country-title">
                        {{ item.country.title }}
                      </span>
                    </div>
                  </div>
                </template>
              </el-autocomplete>
              <template v-if="results.length === 0 && query">
                <div
                  role="region"
                  class="el-autocomplete-suggestion el-popper"
                  style="transform-origin: center top; z-index: 2086; width: 100%; position: absolute; top: 40px; left: 0px;"
                  x-placement="bottom-start"
                >
                  <div class="el-scrollbar">
                    <div
                      class="el-autocomplete-suggestion__wrap el-scrollbar__wrap el-scrollbar__wrap--hidden-default"
                    >
                      <ul
                        class="el-scrollbar__view el-autocomplete-suggestion__list"
                        role="listbox"
                      >
                        <li id="el-autocomplete-8308-item-0" role="option">
                          <div data-v-7ad02228="" class="city">
                            <div data-v-7ad02228="" class="city-right-side">
                              <span
                                data-v-7ad02228=""
                                class="city-title-custom"
                              >
                                Choose a larger city in the same time zone as
                                yours.
                              </span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="el-scrollbar__bar is-horizontal">
                      <div
                        class="el-scrollbar__thumb"
                        style="transform: translateX(0%);"
                      ></div>
                    </div>
                    <div class="el-scrollbar__bar is-vertical">
                      <div
                        class="el-scrollbar__thumb"
                        style="transform: translateY(0%);"
                      ></div>
                    </div>
                  </div>
                  <div
                    x-arrow=""
                    class="popper__arrow"
                    style="left: 35px;"
                  ></div>
                </div>
              </template>
            </el-form-item>
          </el-form>
        </el-row>
      </div>
      <div
        v-show="activeStep === 3"
        style="margin-bottom:15px;text-align:center"
      >
        <img
          src="https://life-chart.art/wp-content/themes/life-chart/images/_02-pp_5-min2.png"
          width="200"
        /><br />
        To proceed with payment, click the button.<br />
        <a
          href="https://paypal.me/izbrannoe?country.x=EN&locale.x=en_EN"
          class="el-button pulse-item"
          target="_blank"
        >
          PAYMENT 19$</a
        ><br />
        <el-row
          type="flex"
          justify="center"
          :gutter="20"
          class="xs-flex-direction-column"
        >
          <el-form
            :model="modelStep3"
            :rules="rulesStep3"
            ref="orderStep3Form"
            class="checkbox-form"
          >
            <el-form-item prop="ispaid" class="flex-center" required>
              <el-checkbox-group
                v-model="modelStep3.ispaid"
                class="el-checkbox-custom"
              >
                <el-checkbox label="I've paid" name="type"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-form>
          <span class="el-dialog__title2">
            After payment, we will send the decoding<br />
            of your chart to your email.
          </span>
        </el-row>
      </div>
      <div
        v-show="activeStep === 4"
        style="margin-bottom:15px;text-align:center"
      >
        <img
          src="https://life-chart.art/wp-content/themes/life-chart/images/congratulations.png"
          width="200"
        /><br />
        <el-row
          type="flex"
          justify="center"
          :gutter="20"
          class="xs-flex-direction-column"
        >
          <span class="el-dialog__title2">
            Congratulations!<br />
            We have received all your details <br />
            and will process your application within 2-3 hours during office
            hours.<br />
            Thank you for your choice!!
          </span>
        </el-row>
      </div>
      <div class="flex-center">
        <div class="stepper-form">
          <el-steps :active="activeStep">
            <el-step></el-step>
            <el-step></el-step>
            <el-step></el-step>
            <el-step></el-step>
          </el-steps>
        </div>
      </div>
    </el-row>
    <el-row slot="footer" class="dialog-footer">
      <el-button
        v-if="activeStep !== 4"
        @click="next"
        type="purple"
        class="min-width-125"
      >
        <span v-if="activeStep !== 3">
          Next step <i class="el-icon-arrow-right"></i>
        </span>
        <span v-else>Last step <i class="el-icon-d-arrow-right"></i></span>
      </el-button>
      <el-button
        v-if="activeStep === 4"
        @click="closeDialog"
        type="primary"
        class="min-width-150"
      >
        Ok
      </el-button>
    </el-row>
  </el-dialog>
</template>

<script>
import Api from "@/api/api";

export default {
  name: "OrderDialog",
  props: {
    orderDialogIncome: {
      type: Boolean,
      default: false
    },
    emailIncome: {
      type: String,
      default: ""
    },
    nameIncome: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      activeName: "",
      activeStep: 1,
      modelStep1: {
        email: this.emailIncome,
        name: this.nameIncome
      },
      modelStep3: {
        ispaid: []
      },
      userProductId: null,
      rulesStep1: {
        email: [
          {
            required: true,
            message: "Please enter your email address",
            trigger: "blur"
          },
          {
            type: "email",
            message: "Please enter a valid email address",
            trigger: "blur"
          }
        ],
        name: [
          {
            required: true,
            message: "Please input Your name",
            trigger: "blur"
          }
        ]
      },
      rulesStep2: {
        month: [
          {
            required: true,
            message: "Please enter a month",
            trigger: "change"
          }
        ],
        day: [
          {
            required: true,
            message: "Please enter a day",
            trigger: "change"
          }
        ],
        year: [
          {
            required: true,
            message: "Please enter a year",
            trigger: "change"
          }
        ],
        time: [
          {
            required: true,
            message: "Please pick a time",
            trigger: "change"
          }
        ],
        city: [
          {
            required: true,
            message: "Please enter the city",
            trigger: "blur"
          }
        ]
      },
      rulesStep3: {
        ispaid: [
          {
            type: "array",
            required: true,
            message: "Should be checked",
            trigger: "change"
          }
        ]
      },
      months: [
        {
          value: "01",
          label: "01"
        },
        {
          value: "02",
          label: "02"
        },
        {
          value: "03",
          label: "03"
        },
        {
          value: "04",
          label: "04"
        },
        {
          value: "05",
          label: "05"
        },
        {
          value: "06",
          label: "06"
        },
        {
          value: "07",
          label: "07"
        },
        {
          value: "08",
          label: "08"
        },
        {
          value: "09",
          label: "09"
        },
        {
          value: "10",
          label: "10"
        },
        {
          value: "11",
          label: "11"
        },
        {
          value: "12",
          label: "12"
        }
      ],
      days: [
        {
          value: "01",
          label: "01"
        },
        {
          value: "02",
          label: "02"
        },
        {
          value: "03",
          label: "03"
        },
        {
          value: "04",
          label: "04"
        },
        {
          value: "05",
          label: "05"
        },
        {
          value: "06",
          label: "06"
        },
        {
          value: "07",
          label: "07"
        },
        {
          value: "08",
          label: "08"
        },
        {
          value: "09",
          label: "09"
        },
        {
          value: "10",
          label: "10"
        },
        {
          value: "11",
          label: "11"
        },
        {
          value: "12",
          label: "12"
        },
        {
          value: "13",
          label: "13"
        },
        {
          value: "14",
          label: "14"
        },
        {
          value: "15",
          label: "15"
        },
        {
          value: "16",
          label: "16"
        },
        {
          value: "17",
          label: "17"
        },
        {
          value: "18",
          label: "18"
        },
        {
          value: "19",
          label: "19"
        },
        {
          value: "20",
          label: "20"
        },
        {
          value: "21",
          label: "21"
        },
        {
          value: "22",
          label: "22"
        },
        {
          value: "23",
          label: "23"
        },
        {
          value: "24",
          label: "24"
        },
        {
          value: "25",
          label: "25"
        },
        {
          value: "26",
          label: "26"
        },
        {
          value: "27",
          label: "27"
        },
        {
          value: "28",
          label: "28"
        },
        {
          value: "29",
          label: "29"
        },
        {
          value: "30",
          label: "30"
        },
        {
          value: "31",
          label: "31"
        }
      ],
      years: [
        {
          value: "2024",
          label: "2024"
        },
        {
          value: "2023",
          label: "2023"
        },
        {
          value: "2022",
          label: "2022"
        },
        {
          value: "2021",
          label: "2021"
        },
        {
          value: "2020",
          label: "2020"
        },
        {
          value: "2019",
          label: "2019"
        },
        {
          value: "2018",
          label: "2018"
        },
        {
          value: "2017",
          label: "2017"
        },
        {
          value: "2016",
          label: "2016"
        },
        {
          value: "2015",
          label: "2015"
        },
        {
          value: "2014",
          label: "2014"
        },
        {
          value: "2013",
          label: "2013"
        },
        {
          value: "2012",
          label: "2012"
        },
        {
          value: "2011",
          label: "2011"
        },
        {
          value: "2010",
          label: "2010"
        },
        {
          value: "2009",
          label: "2009"
        },
        {
          value: "2008",
          label: "2008"
        },
        {
          value: "2007",
          label: "2007"
        },
        {
          value: "2006",
          label: "2006"
        },
        {
          value: "2005",
          label: "2005"
        },
        {
          value: "2004",
          label: "2004"
        },
        {
          value: "2003",
          label: "2003"
        },
        {
          value: "2002",
          label: "2002"
        },
        {
          value: "2001",
          label: "2001"
        },
        {
          value: "2000",
          label: "2000"
        },
        {
          value: "1999",
          label: "1999"
        },
        {
          value: "1998",
          label: "1998"
        },
        {
          value: "1997",
          label: "1997"
        },
        {
          value: "1996",
          label: "1996"
        },
        {
          value: "1995",
          label: "1995"
        },
        {
          value: "1994",
          label: "1994"
        },
        {
          value: "1993",
          label: "1993"
        },
        {
          value: "1992",
          label: "1992"
        },
        {
          value: "1991",
          label: "1991"
        },
        {
          value: "1990",
          label: "1990"
        },
        {
          value: "1989",
          label: "1989"
        },
        {
          value: "1988",
          label: "1988"
        },
        {
          value: "1987",
          label: "1987"
        },
        {
          value: "1986",
          label: "1986"
        },
        {
          value: "1985",
          label: "1985"
        },
        {
          value: "1984",
          label: "1984"
        },
        {
          value: "1983",
          label: "1983"
        },
        {
          value: "1982",
          label: "1982"
        },
        {
          value: "1981",
          label: "1981"
        },
        {
          value: "1980",
          label: "1980"
        },
        {
          value: "1979",
          label: "1979"
        },
        {
          value: "1978",
          label: "1978"
        },
        {
          value: "1977",
          label: "1977"
        },
        {
          value: "1976",
          label: "1976"
        },
        {
          value: "1975",
          label: "1975"
        },
        {
          value: "1974",
          label: "1974"
        },
        {
          value: "1973",
          label: "1973"
        },
        {
          value: "1972",
          label: "1972"
        },
        {
          value: "1971",
          label: "1971"
        },
        {
          value: "1970",
          label: "1970"
        },
        {
          value: "1969",
          label: "1969"
        },
        {
          value: "1968",
          label: "1968"
        },
        {
          value: "1967",
          label: "1967"
        },
        {
          value: "1966",
          label: "1966"
        },
        {
          value: "1965",
          label: "1965"
        },
        {
          value: "1964",
          label: "1964"
        },
        {
          value: "1963",
          label: "1963"
        },
        {
          value: "1962",
          label: "1962"
        },
        {
          value: "1961",
          label: "1961"
        },
        {
          value: "1960",
          label: "1960"
        },
        {
          value: "1959",
          label: "1959"
        },
        {
          value: "1958",
          label: "1958"
        },
        {
          value: "1957",
          label: "1957"
        },
        {
          value: "1956",
          label: "1956"
        },
        {
          value: "1955",
          label: "1955"
        },
        {
          value: "1954",
          label: "1954"
        },
        {
          value: "1953",
          label: "1953"
        },
        {
          value: "1952",
          label: "1952"
        },
        {
          value: "1951",
          label: "1951"
        },
        {
          value: "1950",
          label: "1950"
        },
        {
          value: "1949",
          label: "1949"
        },
        {
          value: "1948",
          label: "1948"
        },
        {
          value: "1947",
          label: "1947"
        },
        {
          value: "1946",
          label: "1946"
        },
        {
          value: "1945",
          label: "1945"
        },
        {
          value: "1944",
          label: "1944"
        },
        {
          value: "1943",
          label: "1943"
        },
        {
          value: "1942",
          label: "1942"
        },
        {
          value: "1941",
          label: "1941"
        },
        {
          value: "1940",
          label: "1940"
        },
        {
          value: "1939",
          label: "1939"
        },
        {
          value: "1938",
          label: "1938"
        },
        {
          value: "1937",
          label: "1937"
        },
        {
          value: "1936",
          label: "1936"
        },
        {
          value: "1935",
          label: "1935"
        },
        {
          value: "1934",
          label: "1934"
        },
        {
          value: "1933",
          label: "1933"
        },
        {
          value: "1932",
          label: "1932"
        },
        {
          value: "1931",
          label: "1931"
        },
        {
          value: "1930",
          label: "1930"
        },
        {
          value: "1929",
          label: "1929"
        },
        {
          value: "1928",
          label: "1928"
        },
        {
          value: "1927",
          label: "1927"
        },
        {
          value: "1926",
          label: "1926"
        },
        {
          value: "1925",
          label: "1925"
        },
        {
          value: "1924",
          label: "1924"
        },
        {
          value: "1923",
          label: "1923"
        },
        {
          value: "1922",
          label: "1922"
        },
        {
          value: "1921",
          label: "1921"
        },
        {
          value: "1920",
          label: "1920"
        },
        {
          value: "1919",
          label: "1919"
        },
        {
          value: "1918",
          label: "1918"
        },
        {
          value: "1917",
          label: "1917"
        },
        {
          value: "1916",
          label: "1916"
        },
        {
          value: "1915",
          label: "1915"
        },
        {
          value: "1914",
          label: "1914"
        },
        {
          value: "1913",
          label: "1913"
        },
        {
          value: "1912",
          label: "1912"
        },
        {
          value: "1911",
          label: "1911"
        },
        {
          value: "1910",
          label: "1910"
        },
        {
          value: "1909",
          label: "1909"
        },
        {
          value: "1908",
          label: "1908"
        },
        {
          value: "1907",
          label: "1907"
        },
        {
          value: "1906",
          label: "1906"
        },
        {
          value: "1905",
          label: "1905"
        },
        {
          value: "1904",
          label: "1904"
        },
        {
          value: "1903",
          label: "1903"
        },
        {
          value: "1902",
          label: "1902"
        },
        {
          value: "1901",
          label: "1901"
        },
        {
          value: "1900",
          label: "1900"
        }
      ],
      results: [],
      query: ""
    };
  },
  computed: {
    isLogged: {
      get() {
        return this.$store.getters.IS_LOGGED;
      },
      set(value) {
        this.$store.commit("IS_Remind", value);
      }
    },
    modelStep2() {
      return this.$store.state.ReportForm;
    },
    city: {
      get() {
        return this.$store.state.ReportCityId.city;
      },
      set(value) {
        this.$store.commit("SET_REPORT_CITY_ID", value);
      }
    }
  },
  methods: {
    next() {
      if (this.activeStep === 1) {
        this.validateForm("orderStep1Form");
      }
      if (this.activeStep === 2) {
        this.validateForm("orderStep2Form");
      }
      if (this.activeStep === 3) {
        this.validateForm("orderStep3Form");
      }
    },
    validateForm(formName) {
      this.$refs[formName].validate(valid => {
        // console.log("validateForm: ", formName, valid);
        if (valid) {
          if (formName === "orderStep2Form") {
            this.modelStep2.time = new Date(this.modelStep2.time);
            const hours = this.modelStep2.time.getHours();
            const minutes = this.modelStep2.time.getMinutes();
            const birthday = `${this.modelStep2.year}-${
              this.modelStep2.month
            }-${this.modelStep2.day} ${hours < 10 ? "0" + hours : hours}:${
              minutes < 10 ? "0" + minutes : minutes
            }`;
            const data = {
              email: this.modelStep1.email,
              first_name: this.modelStep1.name,
              birthday,
              city_id: this.city
            };
            // console.log("validateForm2: ", data);
            this.createOrder(data);
          }
          if (formName === "orderStep3Form") {
            const ispaid = this.modelStep3.ispaid.length ? true : false;
            if (ispaid) {
              const data = {
                user_product_id: this.userProductId,
                is_paid: ispaid
              };
              this.updateOrder(data);
            }
          }
          setTimeout(() => {
            if (formName !== "orderStep3Form") this.activeStep++;
          }, 0);
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      // toggle to income undefined
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields();
        // console.log("orderForm Reset!!!", formName);
      }
    },
    async querySearch(queryString, cb) {
      try {
        const res = await Api.searchSities(queryString);
        this.results = res.cities;
        this.query = queryString;
        const cities = res.cities;
        // console.log(cities);
        const results = queryString
          ? cities.filter(this.createFilter(queryString))
          : cities;
        // call callback function to return suggestions
        cb(results);
      } catch (e) {
        console.log(e);
      }
    },
    createFilter(queryString) {
      return city => {
        return (
          city.title.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelect(item) {
      this.city = item.id;
    },
    handleChange() {
      // Clearing query when click on clear buttom
      if (!this.results.length) {
        this.query = "";
      }
    },
    async createOrder(data) {
      try {
        const result = await Api.createOrder(data);
        // console.log(result);
        if (result.success) {
          this.userProductId = result.user_product.id;
        } else {
          console.log(result.errors);
        }
      } catch (e) {
        console.log(e);
      }
    },
    async updateOrder(data) {
      try {
        const result = await Api.updateOrder(data);
        // console.log(result);
        if (result.success) {
          // this.closeDialog();
          this.activeStep++;
        } else {
          console.log(result.errors);
        }
      } catch (e) {
        console.log(e);
      }
    },
    closeDialog() {
      this.activeStep = 1;
      this.resetForm("orderStep1Form");
      this.resetForm("orderStep2Form");
      this.resetForm("orderStep3Form");
      this.$emit("closeOrderDialog", false);
    }
  }
};
</script>
<style scoped>
.flex-center {
  display: flex;
  justify-content: center;
}
.width-100 {
  width: 100%;
}
.padding-0-10 {
  padding: 0 10px;
}
padding-0 {
  padding: 0 !important;
}
.report-form {
  min-width: 500px;
  max-width: 500px;
}
.checkbox-form {
  min-width: 200px;
  max-width: 200px;
}
.stepper-form {
  min-width: 480px;
  max-width: 480px;
}
.el-form-item__content {
  margin-bottom: 15px !important;
}
.el-form-item {
  margin-bottom: 10px !important;
}
.padding-lt-0 {
  padding-left: 0 !important;
}
.padding-rt-0 {
  padding-right: 0 !important;
}
.pulse-item {
  animation: shadow 1.5s infinite linear;
}
@keyframes shadow {
  0% {
    border-color: #e0e0e0;
    background-color: #edeeff;
  }
  25% {
    border-color: #bcbeff;
    background-color: #e9eaff;
  }
  50% {
    border-color: #4d4f7e;
    background-color: #d7d8fd;
  }
  75% {
    border-color: #bcbeff;
    background-color: #e9eaff;
  }
  100% {
    border-color: #e0e0e0;
    background-color: #edeeff;
  }
}
@media screen and (min-width: 320px) and (max-width: 450px) {
  .report-form,
  .checkbox-form,
  .stepper-form {
    min-width: initial !important;
  }
  .report-form {
    min-width: 320px;
    max-width: 430px;
  }
  .checkbox-form,
  .stepper-form {
    min-width: 320px !important;
    max-width: 430px;
  }
}
</style>
