import Store from "@/store/index";

export const commonErrorsMethods = {
  methods: {
    errorHandler: e => {
      if (e.response) {
        if (e.response.data.code === 100) {
          Store.commit("UPDATE_SERVICE_MODAL", {
            message: e.response.data.message,
            authDialog: true
          });
        }
        if (e.response.status === 400) {
          const keys = e.response.data.errors;
          const ERRORS = Object.values(keys)
            .map(arrayValue => {
              return arrayValue.map(item => item).join("<br />");
            })
            .join("<br />");
          return {
            title: e.response.data.message,
            message: ERRORS,
            show: true
          };
        }
      }
      if (e.message.includes("500")) {
        Store.commit("UPDATE_ERROR_MODAL", {
          message: "Something went wrong...<br /> Contact to Support!<br />"
        });
      }
    }
  }
};
