import Store from "@/store/index";
import Router from "@/router";

export const commonReportingMethods = {
  methods: {
    isDefinedObj: (items, n) => {
      let value = false;
      for (const key in items) {
        // console.log("inside isDefinedObj: ", items[key] === n, items, n);
        if (items[key] === n) value = true;
      }
      return value;
    },
    getMessage: key => {
      return Store.state.Messages[key];
    },
    matchChannels: () => {
      if (
        Object.keys(Store.state.User.chartBodygraph.body_graph.channels2)
          .length !== 0
      ) {
        // формируем правильные (отсортированные) имена для замены имен свойств
        const replacmentKeys = {};
        for (const key in Store.state.User.chartBodygraph.body_graph
          .channels2) {
          replacmentKeys[key] = key
            .split("-")
            .sort((a, b) => +a - +b)
            .join("-");
        }
        // формируем массив уже замёненных литералов
        const replacedItems = Object.keys(
          Store.state.User.chartBodygraph.body_graph.channels2
        ).map(key => {
          const newKey = replacmentKeys[key] || key;
          return {
            [newKey]: Store.state.User.chartBodygraph.body_graph.channels2[key]
          };
        });
        // копируем из массива и присваиваем уже правильные объект
        Store.state.User.chartBodygraph.body_graph.channels2 = replacedItems.reduce(
          (a, b) => Object.assign({}, a, b)
        );
        // console.log(Store.state.User.chartBodygraph.body_graph.channels2);
      }
    },
    matchCompositeChannels: () => {
      if (
        Object.keys(Store.state.User.chartComposite.chart.channels2).length !==
        0
      ) {
        // формируем правильные (отсортированные) имена для замены имен свойств
        const replacmentKeys = {};
        for (const key in Store.state.User.chartComposite.chart.channels2) {
          replacmentKeys[key] = key
            .split("-")
            .sort((a, b) => +a - +b)
            .join("-");
        }
        // формируем массив уже замёненных литералов
        const replacedItems = Object.keys(
          Store.state.User.chartComposite.chart.channels2
        ).map(key => {
          const newKey = replacmentKeys[key] || key;
          return {
            [newKey]: Store.state.User.chartComposite.chart.channels2[key]
          };
        });
        // копируем из массива и присваиваем уже правильные объект
        Store.state.User.chartComposite.chart.channels2 = replacedItems.reduce(
          (a, b) => Object.assign({}, a, b)
        );
        // console.log(Store.state.User.chartComposite.chart.channels2);
      }
      if (
        Object.keys(Store.state.User.chartComposite.chart2.channels2).length !==
        0
      ) {
        // формируем правильные (отсортированные) имена для замены имен свойств
        const replacmentKeys = {};
        for (const key in Store.state.User.chartComposite.chart2.channels2) {
          replacmentKeys[key] = key
            .split("-")
            .sort((a, b) => +a - +b)
            .join("-");
        }
        // формируем массив уже замёненных литералов
        const replacedItems = Object.keys(
          Store.state.User.chartComposite.chart2.channels2
        ).map(key => {
          const newKey = replacmentKeys[key] || key;
          return {
            [newKey]: Store.state.User.chartComposite.chart2.channels2[key]
          };
        });
        // копируем из массива и присваиваем уже правильные объект
        Store.state.User.chartComposite.chart2.channels2 = replacedItems.reduce(
          (a, b) => Object.assign({}, a, b)
        );
        // console.log(Store.state.User.chartComposite.chart2.channels2);
      }
    },
    matchInflictionChannels: () => {
      if (
        Object.keys(Store.state.User.chartTransitBodygraph.chart.channels2)
          .length !== 0
      ) {
        // формируем правильные (отсортированные) имена для замены имен свойств
        const replacmentKeys = {};
        for (const key in Store.state.User.chartTransitBodygraph.chart
          .channels2) {
          replacmentKeys[key] = key
            .split("-")
            .sort((a, b) => +a - +b)
            .join("-");
        }
        // формируем массив уже замёненных литералов
        const replacedItems = Object.keys(
          Store.state.User.chartTransitBodygraph.chart.channels2
        ).map(key => {
          const newKey = replacmentKeys[key] || key;
          return {
            [newKey]:
              Store.state.User.chartTransitBodygraph.chart.channels2[key]
          };
        });
        // копируем из массива и присваиваем уже правильные объект
        Store.state.User.chartTransitBodygraph.chart.channels2 = replacedItems.reduce(
          (a, b) => Object.assign({}, a, b)
        );
        // console.log(Store.state.User.chartTransitBodygraph.chart.channels2);
      }
    },
    // не стрелочная функция, чтобы передать контекст и вызвать scrollToTop()
    sendApplayForChartForm: function() {
      // this.SendApplayForChartIncome = true;
      Router.push("/decoding");
      this.scrollToTop();
    },
    scrollToTop: () => {
      const widgethead = document.getElementById("app");
      widgethead.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    },
    scrollToInfoBlock: () => {
      const widgethead = document.getElementById("DesignTranscribed");
      widgethead.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
  }
};
