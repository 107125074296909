import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    User: {
      id: null,
      name: "",
      reportName: "",
      age: "",
      email: "",
      activeNameTab: "Registration",
      chartBodygraph: null,
      chartComposite: null,
      chartTransit: null,
      chartTransitBodygraph: null,
      city: "",
      cityid: null,
      lastCities: [],
      day: { day: "15" },
      month: { month: "June (6)", id: "06" },
      year: { year: "1990" },
      hour: { hour: "12" },
      minute: { minute: "30" },
      isLogged: false,
      access_token: "life-chart-token",
      is_email_confirmed: false,
      isUnderConstruction: false,
      isUnderConstructionComosite: false,
      isUnderConstructionComositeTooltips: false,
      isUnderConstructionTransit: false,
      isFirstTime: true,
      isChannelsGates: true,
      profile: {}
    },
    Forms: {
      regErrors: "",
      logErrors: "",
      remErrors: "",
      applayForChartDecodeErrors: ""
    },
    Messages: {},
    ReportForm: {
      name: "",
      date: "",
      time: "",
      year: "1980",
      city: ""
    },
    TransitForm: {
      date: "",
      time: "",
      city: ""
    },
    TransitCityId: {
      city: null
    },
    ReportCityId: {
      city: null
    },
    ReportCollaps: {
      activeTypes: ["1"],
      activeProfiles: ["1"],
      activeAuthority: ["1"],
      activeStrategy: ["1"],
      activeFalseSelf: ["1"],
      activeSign: ["1"],
      activeDefinition: ["1"],
      activeAngle: ["1"],
      activeAllCenters: ["1"],
      activeDefinedCenters: ["1"],
      activeUndefinedCenters: ["1"],
      activeChannels: ["1"],
      activeAllGates: ["1"],
      activeSubconscious: ["1"],
      activeConscious: ["1"],
      activeBoth: ["1"]
    },
    CompositeForm: {
      name: "",
      date: "",
      time: "",
      city: "",
      year: "1980",
      name2: "",
      date2: "",
      time2: "",
      year2: "1980",
      city2: ""
    },
    CompositeCitiesId: {
      city: null,
      city2: null
    },
    CompositeCollaps: {
      activeLove: ["1"],
      activeСompatibility: ["1"],
      activeElectro: ["1"],
      activeCompromise: ["1"],
      activeEducational: ["1"],
      activeCompanion: ["1"],
      activeElectroChannels: ["1"],
      activeDominant: ["1"]
    },
    http_options: {
      headers: {
        "X-Api-Key": "life-chart-token",
        "Partner-Id": 1
      },
      withCredentials: true
    }
  },
  mutations: {
    IS_LOGIN(state, v) {
      state.User.isLogged = v;
    },
    IS_EMAIL(state, v) {
      state.User.is_email_confirmed = v;
    },
    IS_UNDER_CONSTR(state, v) {
      state.User.isUnderConstruction = v;
    },
    IS_UNDER_CONSTR_COMPOSITE(state, v) {
      state.User.isUnderConstructionComosite = v;
    },
    IS_UNDER_CONSTR_COMPOSITE_TOOLTIPS(state, v) {
      state.User.isUnderConstructionComositeTooltips = v;
    },
    IS_UNDER_CONSTR_TRANSIT(state, v) {
      state.User.isUnderConstructionTransit = v;
    },
    IS_FIRST_TIME(state, v) {
      state.User.isFirstTime = v;
    },
    IS_CHANNELS_GATES(state, v) {
      state.User.isChannelsGates = v;
    },
    SET_MESSAGES(state, obj) {
      state.Messages = obj;
    },
    SET_NAME(state, v) {
      state.User.name = v;
    },
    SET_REPORT_NAME(state, v) {
      state.User.reportName = v;
    },
    SET_EMAIL(state, v) {
      state.User.email = v;
    },
    SET_USER_ID(state, v) {
      state.User.id = v;
    },
    SET_ACTIVE_NAME_TAB(state, v) {
      state.User.activeNameTab = v;
    },
    SET_TOKEN(state, v) {
      state.User.access_token = v;
      state.http_options.headers = { "X-Api-Key": v };
      // console.log("Headers is: ", state.http_options.headers);
    },
    SET_BODYGRAPH(state, obj) {
      state.User.chartBodygraph = obj;
    },
    SET_COMPOSITE(state, obj) {
      state.User.chartComposite = obj;
    },
    SET_TRANSIT(state, obj) {
      state.User.chartTransit = obj;
    },
    SET_INFLICTION(state, obj) {
      state.User.chartTransitBodygraph = obj;
    },
    SET_REG_ERRORS(state, v) {
      state.Forms.regErrors = v;
    },
    SET_LOG_ERRORS(state, v) {
      state.Forms.logErrors = v;
    },
    SET_REPORT_FORM(state, obj) {
      state.ReportForm = obj;
    },
    SET_REM_ERRORS(state, v) {
      state.Forms.remErrors = v;
    },
    SET_REPORT_CITY_ID(state, id) {
      state.ReportCityId.city = id;
    },
    SET_TRANSIT_FORM(state, obj) {
      state.TransitForm = obj;
    },
    SET_TRANSIT_CITY_ID(state, id) {
      state.TransitCityId.city = id;
    },
    SET_REPORT_COLLAPS(state, obj) {
      state.ReportCollaps = obj;
    },
    SET_COMPOSITE_FORM(state, obj) {
      state.CompositeForm = obj;
    },
    SET_COMPOSITE_CITY_ID(state, id) {
      state.CompositeCitiesId.city = id;
    },
    SET_COMPOSITE_CITY2_ID(state, id) {
      state.CompositeCitiesId.city2 = id;
    },
    SET_COMPOSITE_COLLAPS(state, obj) {
      state.CompositeCollaps = obj;
    },
    SET_PARTNER_ID(state, n) {
      state.http_options.headers["Partner-Id"] = n;
    },
    SET_PROFILE(state, obj) {
      state.User.profile = obj;
    },
    RESET_LOG_REG_FORMS() {},
    RESET_LOG_REM_FORMS() {},
    SET_APPLAY_FOR_CHART_ERRORS(state, v) {
      state.Forms.applayForChartDecodeErrors = v;
    },
    RESET_APPLAY_FOR_CHART_FORMS() {}
  },
  getters: {
    GET_TOKEN: state => {
      return state.User.access_token;
    },
    IS_LOGGED: state => {
      return state.User.isLogged;
    },
    IS_EMAIL_CONFIRMED: state => {
      return state.User.is_email_confirmed;
    },
    IS_UNDER_CONSTRUCTION: state => {
      return state.User.isUnderConstruction;
    },
    IS_UNDER_CONSTRUCTION_COMPOSITE: state => {
      return state.User.isUnderConstructionComosite;
    },
    IS_UNDER_CONSTRUCTION_COMPOSITE_TOOLTIPS: state => {
      return state.User.isUnderConstructionComositeTooltips;
    },
    IS_UNDER_CONSTRUCTION_TRANSIT: state => {
      return state.User.isUnderConstructionTransit;
    },
    IS_CHANNELS_GATES: state => {
      return state.User.isChannelsGates;
    }
  },
  actions: {},
  modules: {}
});
