<template>
  <el-dialog
    :visible.sync="showDialogIncome"
    width="90%"
    :before-close="closeDialog"
    append-to-body
    title="After login/registration you'll have access to all the features of the system"
    class="common-dialog login-dialog"
  >
    <Login
      :email.sync="email"
      :activeNameTab.sync="activeNameTab"
      :isOrderBlock="isOrderBlock"
      @close="closeDialog"
      @openOrderDialogEvent="openOrderDialogEvent"
      @openRemind="openRemindDialog"
    />
  </el-dialog>
</template>

<script>
import Login from "@/components/forms/Login";

export default {
  name: "LoginDialog",
  components: {
    Login
  },
  props: {
    showDialogIncome: {
      type: Boolean,
      default: false
    },
    isOrderBlock: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ""
    },
    activeNameTab: {
      type: String,
      default: ""
    }
  },
  methods: {
    closeDialog(v) {
      this.$store.commit("SET_REG_ERRORS", "");
      this.$store.commit("SET_LOG_ERRORS", "");
      this.$store.commit("RESET_LOG_REG_FORMS", "");
      this.$emit("close", v);
    },
    openRemindDialog(v) {
      this.$emit("openRemind", v);
    },
    openOrderDialogEvent() {
      this.$emit("openOrderDialogEvent");
    }
  }
};
</script>
<style scoped>
@media screen and (min-width: 769px) {
  .login-dialog .el-dialog__body div {
    width: 80% !important;
    margin: 0 auto !important;
  }
}
</style>
