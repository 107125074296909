<template>
  <div>
    <el-table
      :data="reportArr"
      class="no-mobile"
      style="width:100%;margin-bottom:25px"
    >
      <el-table-column label="Name" min-width="90">
        <template slot-scope="scope">
          <span>
            {{ scope.row.first_name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Birthday" min-width="90">
        <template slot-scope="scope">
          <span>
            {{ scope.row.birthday }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Type" min-width="96">
        <template slot-scope="scope">
          <span>
            {{ scope.row.chart_type }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Profile" min-width="100">
        <template slot-scope="scope">
          <span>
            {{ scope.row.chart_profile_title }}
            {{ scope.row.chart_profile }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="Operations" min-width="90">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-document-checked"
            @click="getReport(scope.row.id)"
            round
          >
            Select
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex no-desktop">
      <el-col :span="8" :xs="24">
        <el-card
          shadow="never"
          class="min-height-160"
          style="margin-bottom:25px"
        >
          <ul class="ul-no-li-style">
            <li v-if="reportIncome.first_name">
              <i class="el-icon-user"></i>
              <span>{{ reportIncome.first_name }}</span>
            </li>
            <li v-if="reportIncome.birthday">
              <span>{{ reportIncome.birthday }}</span>
            </li>
            <li v-if="reportIncome.chart_type">
              <span>{{ reportIncome.chart_type }}</span>
            </li>
            <li v-if="reportIncome.chart_profile">
              <span
                >{{ reportIncome.chart_profile_title }}
                {{ reportIncome.chart_profile }}
              </span>
            </li>
          </ul>
          <div>
            <el-button
              size="small"
              type="primary"
              icon="el-icon-document-checked"
              @click="getReport(reportIncome.id)"
              round
            >
              Select
            </el-button>
          </div>
        </el-card>
      </el-col>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivReport",
  props: {
    reportIncome: {
      type: Object,
      default: function() {
        return { show: false };
      }
    }
  },
  data() {
    return {
      reportArr: []
    };
  },
  mounted() {
    this.reportArr = [this.reportIncome];
  },
  methods: {
    getReport(id) {
      this.$emit("selectedReport", id);
    }
  }
};
</script>
<style scoped>
.ul-no-li-style {
  text-align: left;
  padding-left: 0 !important;
}
.ul-no-li-style li {
  list-style-type: none;
}
.ul-no-li-style li i {
  padding-right: 5px;
}
</style>
