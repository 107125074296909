<template>
  <el-dialog
    :title="showErrorDialogIncome.title"
    :visible.sync="showErrorDialogIncome.show"
    width="90%"
    :before-close="closeDialog"
    append-to-body
    class="common-dialog"
  >
    <el-row
      type="flex"
      justify="center"
      :gutter="20"
      class="xs-flex-direction-column"
    >
      <div class="text-center">{{ showErrorDialogIncome.message }}</div>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">Ok</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "ErrorDialog",
  props: {
    showErrorDialogIncome: {
      type: Object,
      default: function() {
        return { show: false };
      }
    }
  },
  data() {
    return {
      confirmEmailText: "You need to confirm your email"
    };
  },
  methods: {
    closeDialog() {
      this.$emit("closeErrorD", { show: false });
    }
  }
};
</script>
