<template>
  <el-dialog
    :visible.sync="SendApplayForChartIncome"
    width="90%"
    :before-close="closeDialog"
    append-to-body
    title="Your human design chart completely decoded"
    class="common-dialog"
  >
    <SendApplayForChartForm :email.sync="email" @close="closeDialog" />
  </el-dialog>
</template>

<script>
import SendApplayForChartForm from "@/components/forms/SendApplayForChartForm";

export default {
  name: "SendApplayForChart",
  components: {
    SendApplayForChartForm
  },
  props: {
    SendApplayForChartIncome: {
      type: Boolean,
      default: false
    },
    email: {
      type: String,
      default: ""
    }
  },
  methods: {
    closeDialog(v) {
      this.$store.commit("SET_APPLAY_FOR_CHART_ERRORS", "");
      this.$store.commit("RESET_APPLAY_FOR_CHART_FORMS", "");
      this.$emit("close", v);
    }
  }
};
</script>
