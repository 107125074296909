<template>
  <div class="home">
    <PoindOfEntry msg="Enter / Registration" />
  </div>
</template>

<script>
// @ is an alias to /src
import PoindOfEntry from "@/components/PoindOfEntry.vue";

export default {
  name: "Home",
  components: {
    PoindOfEntry
  }
};
</script>
